
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderPriceBox",
  props: {
    toman: {
      type: String,
      required: true,
    },
    tether: {
      type: String,
      required: true,
    },
    reverse: Boolean,
  },
});
